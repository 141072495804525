import { LitElement, html, css } from "lit-element";
import "./bk-editor-section.js";
import "./bk-editor-row.js";
import "./bk-input-number.js";
import "./bk-input-color.js";
import "./bk-input-range.js";

class BKEditor extends LitElement {
  static get properties() {
    return {
      blockHeight: String,
      blockWidth: String,
      blockBorderRadius: Number,
      blockBorderColor: String,
      blockBackgroundColor: String,
      blockOpacity: String,
      blockScale: String
    };
  }

  static get styles() {
    return css`
      .bk-editor {
        background-color: var(--theme-background);
        height: 100%;
      }
    `;
  }

  render() {
    return html`
      <div class="bk-editor">
        <bk-editor-section title="Style">
          <bk-editor-row label="Fill" title="the background color of the block">
            <bk-input-color
              slot="input"
              name="backgroundColor"
              .value=${this.blockBackgroundColor}
            ></bk-input-color>
            <div></div>
          </bk-editor-row>

          <bk-editor-row label="Width" title="the width of the block">
            <bk-input-number
              slot="input"
              name="width"
              unit="px"
              .value=${parseInt(this.blockWidth, 10)}
            ></bk-input-number>
            <bk-input-range
              name="width"
              min=${1}
              max=${1024}
              step=${10}
              unit="px"
              .value=${parseInt(this.blockWidth, 10)}
            />
          </bk-editor-row>

          <bk-editor-row label="Height" title="the height of the block">
            <bk-input-number
              slot="input"
              name="height"
              unit="px"
              .value=${parseInt(this.blockHeight, 10)}
            ></bk-input-number>
            <bk-input-range
              name="height"
              min=${1}
              max=${1024}
              step=${10}
              unit="px"
              .value=${parseInt(this.blockHeight, 10)}
            />
          </bk-editor-row>

          <bk-editor-row label="Opacity" title="the opacity of the block">
            <bk-input-number
              slot="input"
              name="opacity"
              min=${0}
              max=${1}
              step=${0.1}
              .value=${this.blockOpacity}
            ></bk-input-number>
            <bk-input-range
              name="opacity"
              min=${0}
              max=${1}
              step=${0.1}
              .value=${this.blockOpacity}
            />
          </bk-editor-row>
        </bk-editor-section>

        <bk-editor-section title="Border">
          <bk-editor-row label="Stroke" title="the border color of the block">
            <bk-input-color
              slot="input"
              name="borderColor"
              .value=${this.blockBorderColor}
            ></bk-input-color>
            <div></div>
          </bk-editor-row>

          <bk-editor-row
            label="Border Width"
            title="the border width of the block"
          >
            <bk-input-number
              slot="input"
              name="borderWidth"
              unit="px"
              .value=${parseInt(this.blockBorderWidth, 10)}
            ></bk-input-number>
            <bk-input-range
              name="borderWidth"
              min=${0}
              max=${100}
              step=${1}
              unit="px"
              .value=${parseInt(this.blockBorderWidth, 10)}
            />
          </bk-editor-row>

          <bk-editor-row
            label="Border Radius"
            unit="px"
            title="the border radius of the block"
          >
            <bk-input-number
              slot="input"
              name="borderRadius"
              unit="px"
              .value=${parseInt(this.blockBorderRadius, 10)}
            ></bk-input-number>
            <bk-input-range
              name="borderRadius"
              min=${0}
              max=${500}
              step=${1}
              unit="px"
              .value=${parseInt(this.blockBorderRadius, 10)}
            />
          </bk-editor-row>
        </bk-editor-section>

        <bk-editor-section title="Transform">
          <bk-editor-row
            label="Scale"
            unit=""
            title="the scale transform of the block"
          >
            <bk-input-number
              slot="input"
              name="scale"
              min=${0.1}
              max=${3}
              step=${0.1}
              unit=""
              .value=${parseFloat(this.blockScale)}
            ></bk-input-number>
            <bk-input-range
              name="scale"
              min=${0.1}
              max=${3}
              step=${0.1}
              unit=""
              .value=${parseFloat(this.blockScale)}
            />
          </bk-editor-row>
          <bk-editor-row
            label="Rotate"
            unit="deg"
            title="the rotate transform of the block"
          >
            <bk-input-number
              slot="input"
              name="rotate"
              min=${-360}
              max=${360}
              step=${1}
              unit="deg"
              .value=${parseFloat(this.blockRotate)}
            ></bk-input-number>
            <bk-input-range
              name="rotate"
              min=${-360}
              max=${360}
              step=${1}
              unit="deg"
              .value=${parseFloat(this.blockRotate)}
            />
          </bk-editor-row>
        </bk-editor-section>
      </div>
    `;
  }
}

customElements.define("bk-editor", BKEditor);

import { LitElement, html, css } from "lit-element";

class BKEditorRow extends LitElement {
  static get properties() {
    return {
      label: String
    };
  }

  static get styles() {
    return css`
      .bk-editor-row {
        display: grid;
        color: var(--theme-primary-text);
        grid-template-columns: calc(var(--theme-unit) * 11) 1fr 1fr;
        padding: var(--theme-margin);
        align-items: center;
        font-size: var(--theme-label-size);
      }
      .bk-editor-row-label {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `;
  }

  render() {
    return html`
      <div class="bk-editor-row">
        <div class="bk-editor-row-label">${this.label}</div>
        <slot name="input"></slot>
        <slot></slot>
      </div>
    `;
  }
}

customElements.define("bk-editor-row", BKEditorRow);

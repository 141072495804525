import { LitElement, html, css } from "lit-element";

class BKInputRange extends LitElement {
  static get properties() {
    return {
      name: String,
      value: Number,
      min: Number,
      max: Number,
      step: String,
      unit: String
    };
  }

  static get styles() {
    return css`
      input {
        margin: 0;
        padding: 0;
        width: 80px;
        margin-left: 30px;
      }
      input:focus {
        color: white;
        border-color: var(--theme-focus);
      }
      input:invalid {
        border-color: red;
      }
    `;
  }

  handleChange(e) {
    const detail = {
      change: this.name,
      value: e.target.value,
      unit: this.unit
    };
    const event = new CustomEvent(`block-change`, {
      composed: true,
      bubbles: true,
      detail
    });
    this.dispatchEvent(event);
  }

  render() {
    const { min = 0, max = 100, step = 1, value } = this;
    return html`
      <input
        type="range"
        min=${min}
        max=${max}
        step=${step}
        .value=${value}
        @input=${this.handleChange}
      />
    `;
  }
}

customElements.define("bk-input-range", BKInputRange);

import { LitElement, html, css } from "lit-element";

class BKInputColor extends LitElement {
  static get properties() {
    return {
      name: String,
      value: Number
    };
  }

  static get styles() {
    return css`
      input[type="color"] {
        width: 25px;
        height: 32px;
        margin: 0;
        padding: 0 2px;
        outline: none;
        display: inline-block;
        color: var(--theme-primary-text);
        border-color: transparent;
        border-top-left-radius: calc(var(--theme-unit) / 4);
        border-bottom-left-radius: calc(var(--theme-unit) / 4);
        background-color: var(--theme-input-background);
      }
      input[type="text"] {
        width: 50px;
        display: inline-block;
        outline: none;
        border-color: transparent;
        color: var(--theme-primary-text);
        height: calc(var(--theme-unit) * 3.5);
        padding: calc(var(--theme-margin) / 2) var(--theme-unit);
        border-left: 1px solid var(--theme-input-border);
        background-color: var(--theme-input-background);
      }
      input:focus {
        color: white;
        border-color: var(--theme-focus);
      }
      input:invalid {
        border-color: red;
      }
      .bk-input-color {
        display: flex;
        border-radius: calc(var(--theme-unit) / 4);
        background-color: var(--theme-input-background);
        border: 1px solid var(--theme-input-border);
      }
    `;
  }

  handleChange(e) {
    const detail = {
      change: this.name,
      value: e.target.value
    };
    const event = new CustomEvent(`block-change`, {
      composed: true,
      bubbles: true,
      detail
    });
    this.dispatchEvent(event);
  }

  render() {
    return html`
      <div class="bk-input-color">
        <input type="color" .value=${this.value} @input=${this.handleChange} />
        <input type="text" .value=${this.value} @input=${this.handleChange} />
      </div>
    `;
  }
}

customElements.define("bk-input-color", BKInputColor);

import { LitElement, html, css } from "lit-element";
import { styleMap } from "lit-html/directives/style-map.js";
import "./bk-workspace.js";
import "./bk-editor.js";

class BKStudio extends LitElement {
  static get styles() {
    return css`
      .bk-studio {
        display: grid;
        grid-template-columns: 1fr 300px;
        max-width: calc(100vw - 2rem);
        height: 85vh;
        margin: 1rem;
        border: 1px solid var(--theme-input-border);
      }

      .block {
        transition: all 0.3s ease;
      }
    `;
  }

  constructor() {
    super();
    this.state = {
      height: "255px",
      width: "255px",
      backgroundColor: "#ffffff",
      borderColor: "#1a1a1a",
      borderStyle: "solid",
      borderWidth: "5px",
      borderRadius: "20px",
      opacity: 1,
      scale: 1,
      rotate: 0
    };
  }

  setState(state) {
    const { scale } = state;
    this.state = {
      ...this.state,
      ...state
    };
    this.requestUpdate();
  }

  handleBlockChange(event) {
    const { detail } = event;
    const { change, value, unit = "" } = detail;
    this.setState({ [`${change}`]: `${value}${unit}` });
  }

  render() {
    const { scale, rotate, ...rest } = this.state;
    const {
      height,
      width,
      backgroundColor,
      borderColor,
      borderWidth,
      borderRadius,
      opacity
    } = rest;
    const txScale = scale ? `scale(${scale})` : "";
    const txRotate = rotate ? `rotate(${rotate})` : "";
    const transform = [txScale, txRotate].join(" ");

    const style = { ...rest, transform };

    return html`
      <div class="bk-studio" @block-change=${this.handleBlockChange}>
        <bk-workspace>
          <div class="block" style=${styleMap(style)}></div>
        </bk-workspace>
        <bk-editor
          .blockHeight=${height}
          .blockWidth=${width}
          .blockBackgroundColor=${backgroundColor}
          .blockBorderColor=${borderColor}
          .blockBorderWidth=${borderWidth}
          .blockBorderRadius=${borderRadius}
          .blockOpacity=${opacity}
          .blockScale=${scale}
          .blockRotate=${rotate}
        ></bk-editor>
      </div>
    `;
  }
}

customElements.define("bk-studio", BKStudio);

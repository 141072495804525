import { LitElement, html, css } from "lit-element";

class BKWorkspace extends LitElement {
  static get styles() {
    return css`
      .bk-workspace {
        border-right: 1px solid var(--theme-border);
        background-color: var(--theme-panel-background);
        display: flex;
        height: 85vh;
        overflow: auto;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
    `;
  }

  render() {
    return html`
      <div class="bk-workspace">
        <slot></slot>
      </div>
    `;
  }
}

customElements.define("bk-workspace", BKWorkspace);

import { LitElement, html, css } from "lit-element";

class BKInputNumber extends LitElement {
  static get properties() {
    return {
      name: String,
      value: Number,
      min: Number,
      max: Number,
      step: String,
      unit: String
    };
  }

  static get styles() {
    return css`
      input {
        width: 100%;
        display: flex;
        align-items: center;
        outline: none;
        color: var(--theme-primary-text);
        height: calc(var(--theme-unit) * 3.5);
        border-radius: calc(var(--theme-unit) / 4);
        background-color: var(--theme-input-background);
        border: 1px solid var(--theme-input-border);
        padding: calc(var(--theme-margin) / 2) var(--theme-unit);
      }
      input:focus {
        color: white;
        border-color: var(--theme-focus);
      }
      input:invalid {
        border-color: red;
      }
    `;
  }

  handleChange(e) {
    const detail = {
      change: this.name,
      value: e.target.value,
      unit: this.unit
    };
    const event = new CustomEvent(`block-change`, {
      composed: true,
      bubbles: true,
      detail
    });
    this.dispatchEvent(event);
  }

  render() {
    return html`
      <input
        type="number"
        min=${this.min}
        max=${this.max}
        step=${this.step}
        .value=${this.value}
        @change=${this.handleChange}
      />
    `;
  }
}

customElements.define("bk-input-number", BKInputNumber);

import { LitElement, html, css } from "lit-element";

class BKEditorSection extends LitElement {
  static get properties() {
    return {
      title: String
    };
  }

  static get styles() {
    return css`
      .bk-editor-section {
        display: flex;
        flex-direction: column;
      }
      .bk-editor-section-title {
        color: var(--theme-active-text);
        background-color: var(--theme-section-title-background);
        padding: calc(var(--theme-margin) * 2);
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 1px;
        text-transform: uppercase;
        border-bottom: 1px solid var(--theme-border);
      }
      .bk-editor-section-content {
        padding: calc(var(--theme-margin) * 2);
      }
    `;
  }

  render() {
    return html`
      <div class="bk-editor-section">
        <div class="bk-editor-section-title">${this.title}</div>
        <div class="bk-editor-section-content">
          <slot></slot>
        </div>
      </div>
    `;
  }
}

customElements.define("bk-editor-section", BKEditorSection);
